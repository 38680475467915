@import "main.scss";
@import "constant.scss";


/**AppBar**/
.MuiAppBar-colorPrimary{
  background-color: transparent !important;
}
.toolbar-left-side{
  display: flex;
  .logo{
    background:  url("../images/LogoColor.png");
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    background-position: center;
    height: 65px;
    width:150px;
  }
}

.dropdownContainer {
  display: flex;
  align-items: center;
  height: 65px;
  font-family:$font_nunito;
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiSelect-root{
    color: $strongRed;
  }
  svg{
    fill:$strongRed
  }
}

/**SideBar***/
.MuiDrawer-paper{
  background:  url("../images/Intersection.svg"),
  linear-gradient(45deg, $strongRed 0%, $lightRed 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, center;
  background-size: 100%, cover;
  justify-content: center;
  color: $white !important;
  display: block !important;
}
.drawer-list {
  margin-top: 35px !important;

}
.MuiDrawer-docked {
  min-height: 100vh;
}
.MuiListItemText-root{
  font-family: $font_nunito;
}

/**Content Page**/
.content-page {
  padding-top: 80px;
  .page-container{
    padding:10px 20px
  }
}

@media only screen and (max-width: 950px) {
  .makeStyles-root-1 {
    display: flex;
    overflow: hidden
  }
}
@media only screen and (max-width:600px){
  .toolbar-left-side{
    .logo{
      height: 50px;
    }
  }
  .dropdownContainer{
    height: 50px;
  }
  .makeStyles-root-1 {
    display: flex;
    overflow: hidden
  }
}
@media only screen and (max-width:400px){
  .makeStyles-root-1 {
    display: flex;
    overflow: hidden
  }
  .toolbar-left-side{
    .logo{
      height: 50px;
    }
  }
  .dropdownContainer{
    height: 50px;
  }
}

