$assets_root: "../";

$font_nunito: "Nunito" !default;
$font_nunito_bold: "Nunito-bold" !default;
$font_nunito_semi_bold: "Nunito-semi-bold" !default;
$font_nunito_light: "Nunito-light" !default;
$font_nunito_bold_italic:"Nunito-bold-italic" !default;

$font_libre_baskerville: "Libre-baskerville" !default;
$font_libre_baskerville_bold: "Libre-baskerville-bold" !default;
$font_libre_baskerville_italic: "Libre-baskerville-italic" !default;

$font_nunito_path: "fonts/Nunito/Nunito-Regular.ttf" !default;
$font_nunito_path_bold: "fonts/Nunito/Nunito-Bold.ttf";
$font_nunito_path_semi_bold: "fonts/Nunito/Nunito-SemiBold.ttf";
$font_nunito_path_light: "fonts/Nunito/Nunito-Light.ttf" ;
$font_nunito_path_bold_italic: "fonts/Nunito/Nunito-BoldItalic.ttf" !default;

$font_libre_baskerville_path: "fonts/LibreBaskerville/LibreBaskerville-Regular.ttf";
$font_libre_baskerville_bold_path: "fonts/LibreBaskerville/LibreBaskerville-Bold.ttf";
$font_libre_baskerville_italic_path: "fonts/LibreBaskerville/LibreBaskerville-Italic.ttf";






