$redColor: #c3262d;
$strongRed: #c3262e;
$darkRed: #92171a;
$mexicanRed: #a72424;
$lightRed: #df5158;
$white: #ffff;
$darkGrey: #53637a;
$shadowColor: #0000001a;
$poloBlue: #394456;
$ligthTamarillo: #92171a00;
$darkTamarillo: #92171a24;
$blue: #00c;
$blackRed: #92171a00;
$lightBlue: #2626bc;
$transparentRed: #c3262d40;
$transparentWhite: #ffffff63;
$yellow: #faed34;
$skyBlue: #d2deff;
$brightRed: #fa0000;
$transparentLightRed: #f9e9ea;
$lightGray: #e1e1e1;
$grey: #00000040;
$success: #50b147;
$GreyishRed: #c3c3c5;
$shadowInput: #f1f8fc1a;
$deviderColor: #cacaca;
$borderColor: rgba(0, 0, 0, 0.42);
$inputTextColor : rgba(0, 0, 0, 0.54)
