@import "main.scss";
@import "common.scss";

.form_control_container {
    background-image: url("../images/Intersection.svg"),
    linear-gradient(45deg, $strongRed 0%, $lightRed 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: left bottom, center;
    background-size: 40%, cover;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, cover;
    justify-content: center;
    height: 100vh;
    .login_form-container {
        justify-content: center;
        margin: auto 20px;
        align-items: center;
        height: max-content;
        border: 1px solid $darkGrey;
        background-color: $white;
        padding: 20px;
        .logo_container{
            display: flex;
            justify-content: center;
            img {
                max-width: 100%;
            }
        }
        .error_server_container {
            color: $brightRed;
            display: flex;
        }
        .form-input {
                margin: 10px;
                background: $white;
                border-bottom: 1px solid $darkGrey;
                .MuiInputLabel-formControl {
                    top: -5px;
                }
                label  {
                    top: 5px;
                }
                &.Mui-error {
                    border-color: $brightRed !important;
                }
                .MuiInput-underline:before,
                .MuiInput-underline:after {
                    content: unset;
                }
                &:focus-within {
                    border-color: $redColor;
                }
                .MuiFormLabel-root {
                    color: $darkGrey;
                }
                .MuiInputLabel-shrink {
                    opacity: 0.5;
                }
                .MuiFormHelperText-root {
                    color: $brightRed;
                }
                span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
                    display: none;
                }
		}
        .login_button_container{
            justify-content: center;
            background-color: $white;
            margin: 10px;
            margin-top: 20px;
            .button {
                width: 50%;
                padding: 10px;
                font-weight: bold;
                background: transparent
                    linear-gradient(112deg, $lightRed 0%, $redColor 100%) 0% 0% no-repeat
                    padding-box !important;
                width: 100%;
            }
        }
    }   
}