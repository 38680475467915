.logo-loader-container {
    height: 100vh;
    display: flex;
    justify-content: center;
}

svg#logo {
    max-width: 500px;
}

#logo-path22 {
	animation: logo-path22_c_o 1000ms linear infinite 500ms normal forwards;
	opacity: 1;
}
@keyframes logo-path22_c_o {
	0% {
		opacity: 0.2;
	}
	83.333333% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}
#logo-path26 {
	animation: logo-path26_c_o 1000ms linear infinite 500ms normal forwards;
	opacity: 1;
}
@keyframes logo-path26_c_o {
	0% {
		opacity: 0.2;
	}
	66.666667% {
		opacity: 0.2;
	}
	83.333333% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
#logo-path30 {
	animation: logo-path30_c_o 1000ms linear infinite 500ms normal forwards;
	opacity: 1;
}
@keyframes logo-path30_c_o {
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 0.2;
	}
	66.666667% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
#logo-path34 {
	animation: logo-path34_c_o 1000ms linear infinite 500ms normal forwards;
	opacity: 1;
}
@keyframes logo-path34_c_o {
	0% {
		opacity: 0.2;
	}
	16.666667% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
#logo-path38 {
	animation: logo-path38_c_o 1000ms linear infinite 500ms normal forwards;
	opacity: 1;
}
@keyframes logo-path38_c_o {
	0% {
		opacity: 0.23;
	}
	16.666667% {
		opacity: 0.23;
	}
	33.333333% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
#logo-path42 {
	animation: logo-path42_c_o 1000ms linear infinite 500ms normal forwards;
	opacity: 1;
}
@keyframes logo-path42_c_o {
	0% {
		opacity: 0.2;
	}
	33.333333% {
		opacity: 0.2;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
