@import "colors.scss";
@import 'fonts.scss';

@font-face {
    font-family: $font_nunito;
    src: url($assets_root + $font_nunito_path) format("truetype");
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: $font_nunito_bold;
    src: url($assets_root + $font_nunito_path_bold) format("truetype");
    font-style: normal;
    font-display: bold;
  }
  
  @font-face {
    font-family: $font_nunito_semi_bold;
    src: url($assets_root + $font_nunito_path_semi_bold) format("truetype");
    font-style: normal;
    font-display: normal;
  }
  
  @font-face {
    font-family: $font_nunito_light;
    src: url($assets_root + $font_nunito_path_light) format("truetype");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: $font_nunito_bold_italic;
    src: url($assets_root + $font_nunito_path_bold_italic) format("truetype");
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: $font_libre_baskerville;
    src: url($assets_root + $font_libre_baskerville_path) format("truetype");
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: $font_libre_baskerville_bold;
    src: url($assets_root + $font_libre_baskerville_bold_path) format("truetype");
    font-style: normal;
    font-weight: bold;
  }
  
  @font-face {
    font-family: $font_libre_baskerville_italic;
    src: url($assets_root + $font_libre_baskerville_italic_path) format("truetype");
    font-style: normal;
    font-weight: normal;
  }

  body {
    font-family: $font_nunito !important;
  }
  