@import "main.scss";
@import "common.scss";

.add-reference-container {
  text-align: end;
  display: flex;
  width: 100%;
}
.button.add-reference {
    width: auto !important;
}

.MuiTabs-flexContainer {
  justify-content: center;
  padding-top: 20px;
}

.complete-form-error {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-family: $font_nunito_bold;
  color: $redColor;
}

.dataTable-container {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  .MuiTypography-h6 {
    font-family: $font_nunito_bold;
  }
  .MuiTableCell-root {
    font-family: $font_nunito;
  }
  td.MuiTableCell-root.MuiTableCell-body {
    word-break: break-word;
  }
  .MuiTableCell-head {
    font-family: $font_nunito_bold;
    font-size: 16px;
  }
  td:first-child {
    text-transform: uppercase;
  }
  th:last-child {
    svg.MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc {
      display: none;
    }
  }
  .reference-image-container {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
  .button-conatainer {
    display: flex;
    button {
      background-color: transparent !important;
      border: 0px !important;
      outline: none !important;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .icon-delete {
      fill: $lightRed;
    }
    button:hover {
      background-color: $transparentLightRed !important;
    }
  }
  .ForwardRef-root-20 {
    width: 25%;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  div#tableTitle {
    padding-bottom: 10px;
  }
}

div#delete-dialog {
  .MuiDialog-paperScrollPaper {
    width: 40% !important;
  }
  .delete-dialog-content {
    padding: 80px 40px;
    text-align: center;
    font-size: 17px;
  }
}

@media only screen and (max-width: 950px) {
  .dataTable-container {
    td:nth-child(3),
    th:nth-child(3) {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .dataTable-container {
    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
      flex-wrap: wrap;
    }
    td:nth-child(3),
    th:nth-child(3),
    td:nth-child(2),
    th:nth-child(2) {
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  .dataTable-container {
    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
      flex-wrap: wrap;
    }
    td:nth-child(3),
    th:nth-child(3),
    td:nth-child(2),
    th:nth-child(2) {
      display: none;
    }
  }
}