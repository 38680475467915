@import "main";
/*** button style ***/
.button.MuiButton-root {
    font-family: $font_nunito_bold;
    background-color: $lightRed;
    color: $white;
    padding: 10px 30px;
    border-radius: 10px;
    display: inline-block;
	vertical-align: middle;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	.loader-component {
		width: auto;
		margin-left: 10px;
	}
}
.button:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    border-radius: 10px;
    border: 2px solid $strongRed;
	background: $white;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	opacity: 0;
}
.button.MuiButton-root:hover {
	background-color: $redColor;
}
.button:hover,
.button:focus,
.button:active {
	color: $darkGrey;
}
@keyframes buttonAnimation {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	20% {
		opacity: 0;
		transform: scaleX(0.1);
	}
	30% {
		opacity: 0;
		transform: scaleX(0.2);
	}
	40% {
		opacity: 0.4;
		transform: scaleX(0.5);
	}
	50% {
		opacity: 0.5;
		transform: scaleX(0.7);
	}
	60% {
		opacity: 0.6;
		transform: scaleX(0.8);
	}
	100% {
		opacity: 1;
		transform: scaleX(1);
	}
}
.button:hover:before,
.button:focus:before,
.button:active:before {
	animation: buttonAnimation 0.5s forwards 1;
}

/** from modal styles **/
.MuiDialog-paperScrollPaper {
	width: 60% !important;
	max-height: calc(100% - 100px);
}
.MuiDialog-paperWidthSm {
	background: url("../images/Intersection_white.svg");
	background-repeat: no-repeat;
	background-position: right bottom;
	max-width: 100% !important;
}
.dialog-content {
	h2.MuiTypography-root.MuiTypography-h6 {
		display: flex;
		justify-content: center;
		align-items: center;
	} 

	.MuiDialogTitle-root {
		background: linear-gradient(45deg, $strongRed 0%, $lightRed 100%);
		color: $white;
		text-align: center;
		text-transform: uppercase;
		.MuiTypography-h6 {
			font-family: $font_nunito_bold_italic !important;
			letter-spacing: 0.05em !important;
		}
	}
	.MuiDialogActions-root{
		display: flex;
		justify-content: center;
		.MuiButton-root{
			display:flex;
		}
	}
}
.form-container{
	margin-left: auto;
	margin-right: auto;
	color: $inputTextColor !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0px;
	p.MuiFormHelperText-root {
		color: $lightRed;
		padding: 5px;
		font-size: 13px;
	}
	.form-input {
		padding: 10px;
		width: 100%;
		display: flex;
		flex-direction: column;
		
		.MuiInputBase-input {
			color: $inputTextColor;
		}
		.MuiInputLabel-animated {
			color: $inputTextColor;
		}
		.MuiInput-underline:before {
			color: $darkGrey;
			border-bottom: 0px solid $inputTextColor
		}
		.MuiInput-underline:after {
			border-bottom: 0px solid $redColor
		}
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: 0px solid $borderColor;
		}
	}
	.MuiFab-extended.MuiFab-sizeSmall {
		width: auto;
		background-color: $lightRed;
		color: $white;
		padding-right: 15px;
	}
	.image-name-container {
		padding-left: 10px;
	}
	fieldset.MuiFormControl-root {
		padding: 0px 20px;
		border: 1px solid $borderColor;
		background-color: $white;
		border-radius: 5px;
		width: 100%;
		legend.MuiFormLabel-root {
			padding: 0 10px;
			color: $inputTextColor;
		}
		.MuiFormGroup-root {
			flex-direction: row;
			label.MuiFormControlLabel-root {
				margin-right: 60px;
			}
			span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
				color: $inputTextColor;
			}
			.MuiRadio-colorSecondary.Mui-checked{
				color: $redColor !important;
			}
		}
	} 
	fieldset.MuiFormControl-root.radio-botton-container,
	fieldset.MuiFormControl-root.input-file {
		padding: 20px;
	}
	.errors-hide {
		display: none;
	}
	
	.MuiFormLabel-asterisk.Mui-error {
		color: $darkGrey !important;
	}
}
//Snackbar
.success .MuiSnackbarContent-root {
	background-color: $success !important;
	color: $white;
}
.error .MuiSnackbarContent-root {
	background-color: $brightRed !important;
	color: $white;
}
//loader
.loader-component {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	svg {
		color: $redColor;
	}
}

@media only screen and (max-width: 950px) {

}
@media only screen and (max-width:600px){
	.MuiDialog-paperScrollPaper {
		width: 100% !important;
	}
}
@media only screen and (max-width:400px){
	.MuiDialog-paperScrollPaper {
		width: 100% !important;
	}
}
